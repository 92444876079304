<script>
  export let isOpen = false;

  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";
  import { settings_store } from "$lib/store.js";
  import { get } from "svelte/store";

  const dispatch = createEventDispatcher();
  let old_setting_values;

  $: if (isOpen) {
    //make deep copy of the settings, otherwise it is only a reference and is still updated
    old_setting_values = JSON.parse(JSON.stringify(get(settings_store)));
  }

  function exitNoChange() {
    settings_store.set(old_setting_values);
    isOpen = false;
    dispatch("close");
  }

  function trackDonationBanner() {
    window._paq.push(["trackEvent", "donation-banner", "clicked"]);
  }
</script>

<!-- ---------------------------------------------------------------------- -->

{#if isOpen}
  <div class="md-modal-container" on:click={exitNoChange} on:keydown={exitNoChange} role="button" tabindex="-1">
    <div class="md-content">
      <div class="buttons">
        <button title={$_("close")} class="exit-button" on:click={exitNoChange} on:keypress={exitNoChange} />
      </div>

      <h1>Login</h1>
      <div class="login setting-block">
        <img src="/icons/icon_construction.svg" alt="under construction" width="48" height="48" />
        <p>
          {$_("This feature is still under construction")}
          <span class="mark">{$_("Help us with a donation to develop:")}</span>
        </p>
        <ul>
          <li>{$_("the login section")}</li>
          <li>{$_("want-to-see-lists")}</li>
          <li>{$_("seen-lists")}</li>
          <li>{$_("multiple search profiles")}</li>
          <li>{$_("personal recommendations")}</li>
          <li>{$_("and more")}</li>
        </ul>

        <a
          href="https://www.paypal.com/donate/?business=VCQTYXPW42KUL&no_recurring=0&item_name={$_(
            'paypal_button_text'
          )}&currency_code=CHF"
          rel="noopener noreferrer"
          on:click={() => trackDonationBanner()}
          target="_blank">
          <img class="donate-btn" alt="DONATE" />
        </a>
        <p>{$_("Thanks")}!</p>
      </div>
      <h1>Settings</h1>
      <div class="cookies setting-block">
        <div>
          <strong>{$_("advertisement_cookies")}:</strong>
          {$_("cookie_consent_ads")}
        </div>
        <label class="switch" for="cookie_ads">
          <input type="checkbox" id="cookie_ads" name="ads cookies" bind:checked={$settings_store.consent_ad_cookies} />
          <span class="slider"></span>
        </label>
      </div>
    </div>
  </div>
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .md-modal-container {
    display: flex;
    justify-content: center;
    align-items: start;
    position: fixed;
    overflow-y: scroll;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: var(--black-80);
  }
  .md-content {
    width: 420px;
    min-width: 300px;
    height: max-content;
    position: relative;
    margin-top: 60px;
    background-color: var(--black);
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    color: var(--white-grey);
    font-size: 16px;
    text-align: left;
    /* overflow: scroll; */
  }
  .login {
    margin-bottom: 36px;
  }
  .login > img {
    float: left;
    margin-top: 10px;
    margin-right: 4px;
  }
  .setting-block {
    border-radius: 8px;
    padding: 1em;
    background-color: var(--dark-grey);
  }
  .buttons {
    margin-right: 1em;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0;
  }
  .exit-button {
    background: url(/buttons/btn_close_details.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: left;
    cursor: pointer;
    margin-right: 1em;
  }
  .exit-button:hover {
    background: url(/buttons/btn_close_details_hover.svg) no-repeat;
  }
  /* need to enforce font weight on strong and h1 because they get overruled by global.css */
  strong {
    color: var(--lightest-blue);
    font-weight: 400;
  }
  .mark {
    color: var(--lightest-blue);
  }
  h1 {
    font-weight: 300;
    color: var(--lightest-blue);
  }
  .donate-btn {
    content: url("/buttons/btn_donate.png");
    width: 240px;
    max-width: 100%;
    display: block;
    /* margin: auto; */
  }
  .donate-btn:hover {
    content: url("/buttons/btn_donate_hover.png");
    cursor: pointer;
  }
  /* for the cookie consent --------------------------------------*/
  .cookies {
    margin-top: 1em;
  }
  /* iOS styled slider-switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    min-width: 60px;
    height: 34px;
    margin-bottom: auto;
    margin-top: 1em;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-grey);
    transition: 0.4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white);
    transition: 0.3s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: var(--lightest-blue);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(--lightest-blue);
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:disabled + .slider {
    background-color: var(--lightest-blue); /* Grey background for disabled slider */
    cursor: not-allowed;
  }
  input:disabled + .slider:before {
    background-color: var(--white-10); /* Optional: Change knob color for disabled state */
  }
  /* end cookie consent --------------------------------------*/
</style>
